import React, { Component } from "react"
import { navigate } from "gatsby"
import { connect } from "react-redux"
import Formsy from 'formsy-react';

import Layout from "../components/layout"
import MyInput from "../components/MyInput"
import ErrrorHandel from "../components/ErrrorHandel";
import MyInputMask from "../components/MyInputMask";
import MyCheckbox from "../components/MyCheckbox";

import SEO from "../components/seo"
import * as actionCreators from '../store/actions';
import { Link } from "react-router-dom";

class ConfirmPage extends Component {

  state = {
    recordId:null,
    vehicleInfo:null,
    vehicleFeature:null,
    userInfo:null,
    planInfo:null,
    dealertoken:null,
    curplan:null,
    canPay:null,
    gatewayerror:null,
    expErr : null,
    totAmt:0,
    locSurc:{'Commercial':'isCommercial','EnhanchedTech':'isEnhanchedTech','LiftKit':'isLiftKit','RideShare':'isRideshare',
                        'Branded':'isBranded','Canadian':'isCanadian','WaitPeriodWaiver':'isWPW', 'FULLDIV':'FULLDIV','HALFDIV':'HALFDIV',
                        'Seals and Gaskets':'SealsGaskets'},
    dispSurc:{'Commercial':'Commercial','EnhanchedTech':'Enhanched Tech','LiftKit':'LiftKit','RideShare':'Rideshare',
                        'Branded':'Branded','Canadian':'Canadian','WaitPeriodWaiver':'Wait Period Waiver', 'FULLDIV':'Full Dividend','HALFDIV':'Half Dividend',
                        'Seals and Gaskets':'Seals & Gaskets'},
    summ:null,
    cb1:false, cb2:false,
    loggeduserid:null,
    userType:'emp',
    couponCode:null,
    couponStat:0,
    discountType:null,
    discountVal:0,
    discountAmt:0,
    discountCode:null,
    dateVal:null,
    initdate:null,
    postpay:0,
    planamt:0,
    baklnk:null
  }

  componentDidMount = async() => {
    const { showLoader} = this.props;
    showLoader();
    let param = this.getQueryString(this.props.location.search);
    
    if(param && param.token){
      let dealertoken = param.dealertoken ? param.dealertoken : null;
      let loggeduserid = param.dluid ? param.dluid : null;
      let userType = param.utm ? 'cust' : 'emp'; 
      let baklnk = "/?q="+param.token+(dealertoken?'&dealertoken='+dealertoken:'')+(loggeduserid?'&loggeduserid='+loggeduserid:'')
      this.setState({ dealertoken:dealertoken,recordId:param.token,loggeduserid:loggeduserid,userType:userType,baklnk:baklnk});
      this.setState({dateVal:this.getMyDate(0,1),initdate:this.getMyDate(0,1)});
      await this.getFromDirect(param,dealertoken);
    }

  }
  backLink = () => {
    window.location.href = this.state.baklnk;
  }
  getMyDate = (isToday,disply) => {
    let dte = new Date();
    if(isToday==0){
        dte.setDate(dte.getDate() + 1);
    }
    var dd = String(dte.getDate()).padStart(2, '0');
    var mm = String(dte.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = dte.getFullYear();
    if(disply)
    return (mm + '/' + dd + '/' + yyyy);
    else
    return (yyyy + '/' + mm + '/' + dd);
  }
  getQueryString = (data) => {
    if (data !== '') {
        const search = data.substring(1);
        return JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
    }
  }
  checkLead = (leadId) => {
    let uri = process.env.REST_API_URL+'checkLead/?leadId='+leadId;
    fetch(uri)
    .then(res => res.json())
    .then((res) => {
        if(!res.status){
            this.setState({expErr:true,curplan:null});
        }
    });
  }

  getFromDirect = async(param, dealertoken) => {
    
    const { hideLoader} = this.props;
    const { locSurc } = this.state;
        let paramId = param.token;
        let uri = process.env.REST_API_URL+'getPlanById'+(dealertoken?'/?dealertoken='+dealertoken:'');
        await fetch(uri,{
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                _id: paramId,
            })
        }).then(res => res.json()).then(async(res) => {
            if(res.data){
                let curplan = {};
                param.p = decodeURI(param.p);
                Object.keys(res.data.plans).forEach(async(idx)=> {
                  if(res.data.plans[idx]['name'] === param.p){
                    curplan = res.data.plans[idx];
                  }
                });
                
                if(curplan['actual'] && typeof curplan['deduct'][param.d]!== 'undefined'){
                  let vehicleFeature = res.data.step1.vehicleFeature;
                  let planInfo = res.data.step3;
                  planInfo.levelOfCoverage = param.p;
                  planInfo.payAtClaimTime = param.d;

                  curplan.surcharges= {};
                  console.log(vehicleFeature);
                  
                  let totAmt = curplan['actual']+curplan['deduct'][param.d];
                  let planamt = totAmt;
                  Object.keys(locSurc).forEach(async(s)=> {
                    let t = locSurc[s].substring(2,4);
                    
                    if(param[t]){
                      locSurc[s] = curplan['surc'][s];
                      totAmt = totAmt + curplan['surc'][s];
                      vehicleFeature[s] = true;
                    } else {
                      //console.log(locSurc[s]+'---'+t)
                      locSurc[s] = 0;
                      vehicleFeature[s] = false;
                    }
                  });

                  this.setState({ locSurc:locSurc, vehicleInfo: res.data.step1.vehicleInfo,vehicleFeature: vehicleFeature,userInfo:res.data.step2,planInfo:planInfo, curplan:curplan, totAmt:totAmt});
                  /* let summ = {};
                  
                  if(curplan.surc['AWD/4x4']){
                    summ['AWD/4x4']=curplan.surc['AWD/4x4'];
                    summ.plan = summ.plan - curplan.surc['AWD/4x4']
                  }
                  if(curplan.surc['AWD/4x4']){
                    summ['AWD/4x4']=curplan.surc['AWD/4x4'];
                    summ.plan = summ.plan - curplan.surc['AWD/4x4']
                  } */

                
              } else {
                this.setState({expErr:true});
              }
              this.checkLead(res.data.step2.leadId);
            } else{
              this.setState({expErr:true});
            }
            hideLoader();
        });
  };

  
  enablePay = () => {
    const { cb1, cb2 } = this.state;
    if(cb1 && cb2)
      this.setState({ canPay: true});
    else
    this.setState({ canPay: false});
  }

  disablePay = () => {
      this.setState({ canPay: false});
  }
  
  cbchnage = (cb,e) => {
    if(cb =='cb1') this.setState({ cb1: e});
    if(cb =='cb2') this.setState({ cb2: e});
    this.enablePay()
  }

addCard = async(model) => {
    const { showLoader, hideLoader } = this.props;
    const {planInfo, dealertoken} = this.state;
    showLoader();
    //await this.makePay();
    let uri = process.env.REST_API_URL+'checkcard'+(dealertoken?'/?dealertoken='+dealertoken:'');
    await fetch(uri,{
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
            recordId:planInfo.recordId,
            userId:planInfo.userId,
            cardFirstName: model.cardFirstName,
            cardLastName: model.cardLastName,
            cardNo: model.cardNo,
            cvv:model.cvv,
            expMMYY: model.expMMYY,
        })
      })
    .then(res => res.json())
    .then(async(res) => {
        if(res.status === 0){
            let errorCode='';
            errorCode = res.type;
            if(errorCode === 'cardNo'){
                this.refs[`payFrom`].updateInputsWithError({
                    [`${errorCode}`]: res.msg,
                });
                this.setState({ gatewayerror: null })
            } else {
                this.setState({ gatewayerror: res.msg })
            }
        } else {
            this.savePlan();
            console.log('wait')
            await new Promise(resolve => setTimeout(resolve, 15000)); 
            console.log('pay')
            await this.makePay();
        }
    });
    
}

savePlan = async() => {
  var {dealertoken, loggeduserid, recordId, planInfo, curplan, totAmt,locSurc} = this.state;
  //{ vehicleInfo: res.data.step1.vehicleInfo,vehicleFeature: vehicleFeature,
  //userInfo:res.data.step2,planInfo:planInfo, curplan:curplan, totAmt:totAmt}
  
      let ded_code = '';
      curplan['deductibles'].forEach((tmp) =>{
          if(parseInt(tmp.amt) === parseInt(planInfo.payAtClaimTime)){
              ded_code = tmp.id;
          }
      });
      var seals = 0;
      var dividendType = "";
      var dividendPrice = "";

    if(locSurc['Seals and Gaskets']>0){
        totAmt = totAmt - locSurc['Seals and Gaskets'];
        seals = locSurc['Seals and Gaskets'];
        console.log('Seals and Gaskets' + totAmt)
    }
    if(locSurc['FULLDIV']>0){
        totAmt = totAmt - locSurc['FULLDIV'];
        dividendType = 'FULLDIV';
        dividendPrice = locSurc['FULLDIV'];
        console.log('FULLDIV' + totAmt)

    } 
    if(locSurc['HALFDIV']>0){
        totAmt = totAmt - locSurc['HALFDIV'];
        dividendType = 'HALFDIV';
        dividendPrice = locSurc['HALFDIV'];
        console.log('HALFDIV' + totAmt)
    }
              
      let reqin = {
          keepVehicle: 60,
          levelOfCoverage: planInfo.levelOfCoverage,
          payAtClaimTime: planInfo.payAtClaimTime,
          milesPerMonthMin : 0,
          milesPerMonthMax : 0,
          monthlyPayment : totAmt,
          activationCost : 0,
          covered : curplan['covered'],
          rateId : curplan['rateId'],
          recordId : recordId,
          vehicleId : planInfo.vehicleId,
          deductibleCode : ded_code,
          deductible : ded_code,
          loggeduserid:loggeduserid,
          seals:seals,
          dividendType:dividendType,
          dividendPrice:dividendPrice,
          optCharges:locSurc,
      }
      console.log(reqin)
      let uri = process.env.REST_API_URL+'saveplan'+(dealertoken?'/?dealertoken='+dealertoken:'');
      await fetch(uri,{
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify(reqin)
      }).then(res => res.json()).then((res) => {
          if(res.status==="Success"){
            console.log('plan updated')
          } else {
            console.log('update failed')
          }
      });
  
}
resendPayLink = async() => {
  const { showLoader, hideLoader } = this.props
  var {dealertoken, recordId, planInfo, loggeduserid} = this.state;
  showLoader();
  let uri = process.env.REST_API_URL+'resendPayLink'+(dealertoken?'/?dealertoken='+dealertoken:'');
  let reurl = encodeURI(this.props.location.href);
  await fetch(uri,{
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({userId:planInfo.userId,loggeduserid:loggeduserid,reurl:reurl})
  }).then(res => res.json()).then((res) => {
      if(res.status){
          this.setState({ paylnk: null});
          hideLoader();
      }
  });

}

makePay = async() => {
    
    const { showLoader, hideLoader } = this.props;
    const { planInfo, dealertoken, curplan, recordId, vehicleFeature,locSurc,loggeduserid, discountCode,dateVal,postpay  } = this.state;
    
    let dividendType = '';
    let dividendPrice = 0;
    let Seals = 0;
    if(vehicleFeature['FULLDIV']){
      dividendType = 'FULLDIV';
      dividendPrice = locSurc['FULLDIV']
    } else if(vehicleFeature['HALFDIV']){
      dividendType = 'HALFDIV';
      dividendPrice = locSurc['HALFDIV']
    }
    if(vehicleFeature['SealsGaskets']){
      Seals = locSurc['Seals and Gaskets'];
    }
    
    let uri = process.env.REST_API_URL+'makepay'+(dealertoken?'/?dealertoken='+dealertoken:'');
    await fetch(uri,{
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        _id: recordId,
        dividendType:dividendType,
        dividendPrice:dividendPrice,
        Seals:Seals,
        retailPrice : planInfo.monthlyPayment,
        productCode: curplan.productCode,
        loggeduserid:loggeduserid,
        coupon:discountCode,
        startDate:dateVal,
        postpay:postpay
      })
    })
    .then(res => res.json())
    .then((res) => {
        hideLoader();
        if (res.status ===1) {
          navigate(`/congratulation/?q=${res.contractId}&id=`+planInfo.recordId+(dealertoken?'&dealertoken='+dealertoken:''));
        } else {
          this.setState({ gatewayerror: res.msg })
        }
    });
};

couponChange = (val) => {
  this.setState({couponCode:val})
  if(val.length>3){
    this.setState({couponStat:2})
  } else {
    this.setState({couponStat:1})
  }
}
applyCoupon = async() =>{
  const { showLoader, hideLoader } = this.props;
  var { couponCode, discountAmt, totAmt,dealertoken} = this.state;
  showLoader();
  
  let uri = process.env.REST_API_URL+'checkCoupon'+(dealertoken?'/?dealertoken='+dealertoken:'');
  await fetch(uri,{
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({
      coupon:couponCode,
    })
  })
  .then(res => res.json())
  .then((res) => {
      hideLoader();
      if (res.valid ===1) {
        //console.log(res)
        if(res.type == 'f'){
          discountAmt = res.value;
        } else if (res.type == 'p'){
          discountAmt = Math.round(totAmt * res.value / 100,0);
        }
        this.setState({ couponStat:3, discountAmt:discountAmt, discountCode:couponCode })
      } else {
        this.setState({ couponStat:4, discountAmt:0,discountCode:null })
      }
      
  });
}
showCoupon = () => {
  this.setState({couponStat:1})
}

postpay = (value) => {
  this.setState({ postpay: value })
  if(!value)
      this.setState({ dateVal: this.getMyDate(0,1) })
}
dateChange = (dt) => {
  if(dt.length == 10){
     let splt = dt.split("/");
     let eDt = new Date(splt[2]+'-'+splt[0]+'-'+splt[1]);
     let nDt = new Date(this.getMyDate(1,0));
     let diffTime = (eDt - nDt);
     let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

     console.log(eDt,nDt, diffTime, diffDays);

     if(diffDays>1){
         this.setState({dateVal:dt})
     } else {
         this.setState({dateVal:null})
     }
     
     //let dif = eDt.getDate() - nDt.getDate();
     //console.log(dif+ '-------'+ splt[2]+'-'+splt[0]+'-'+splt[1])
  } else {
     this.setState({dateVal:null})
  }
   
 }


  render() {
    const { expErr,vehicleInfo,vehicleFeature,userInfo,planInfo,curplan,locSurc,
      dispSurc, canPay, gatewayerror, totAmt, cb1,cb2,userType,
      couponStat,discountAmt,dateVal, initdate,postpay } = this.state;
    if(curplan){
    } else {
     
      return (
        <Layout layoutType="payment">
          <SEO title="Payment" keywords={[`Extended warranty`]} />
          <section className="banner covered-banner">
            <div className="banner-inner">
              
              {expErr && 
              <div className="container">
                <h1>Sorry!</h1>
                <h5>Your email link has been expired.</h5>
                </div>
              }
              
            </div>
          </section>
      </Layout>
      )
    }
    
    
    return (
      <Layout layoutType="payment">
        <SEO title="Payment" keywords={[`servicecontract.com`, `service contracts`, `Extended warranty`]} />
        <section className={global.dealertoken?'grayish paylnk':'paylnk'}>
          <div className="custom-container vehinfo">
           <div className="title-container last">
              <div className="final-image">
                <img src={planInfo.logoUrl} alt="Logo" className="clogo" />
              </div>
            </div>
            <div className="body-content confirm-content">
              <div className="cofirm-left-content usrinfo">
                <div className="confirm-box">
                  <div className="confirm-box-inner">
                    <ul>
                      <li>
                        <span>Your Info</span>
                        <p>{userInfo.firstName} {userInfo.lastName}</p>
                      </li>
                      {userInfo.address &&
                      <li >
                        <span>Address</span>
                        <p>{userInfo.address}, {userInfo.city}, {userInfo.state} {userInfo.zipcode}</p>
                      </li>
                      }
                      <li className="setting-1">
                        <span>Vehicle</span>
                        <p>{vehicleInfo.year} {vehicleInfo.maker} {vehicleInfo.model}</p>
                      </li>
                      
                      {vehicleInfo.vin &&
                      <li className="setting-1">
                        <span>Vin</span>
                        <p>{vehicleInfo.vin}</p>
                      </li>
                      }
                      <li className="setting-2">
                        <span>Plan</span>
                        <p>{planInfo.levelOfCoverage} / Unlimited Miles</p>
                      </li>
                      
                      <li className="setting-5">
                        <span>Deductible</span>
                        <p>${planInfo.payAtClaimTime}</p>
                      </li>
                      </ul>
                      </div>
                      </div>
                </div>
              
                <div className="cofirm-right-content planinfo">
                {vehicleInfo.vin &&
                  <div className="ccout">
                    <div className="cctitle" onClick={() => {this.showCoupon()}}>Have a Coupon?</div>
                    {couponStat>0 &&
                    <div>
                    <Formsy>
                    <MyInput
                        name="coupon"
                        type="text"
                        className="ccbox"
                        placeholder="Coupon Code"
                        value=""
                        valueChanges={(e) => this.couponChange(e)}
                    />
                    {couponStat==4 &&
                    <div className="ccerr">Invalid Coupon Code</div>
                    }
                    {couponStat==3 &&
                    <div className="ccok">Discount Applied : ${discountAmt}</div>
                    }
                    <button onClick={() => {this.applyCoupon()}} className={couponStat==1?"ccbtn disabled":"ccbtn"}>Apply</button>
                    </Formsy>
                    </div>
                    }
                  </div>
                }

{/* 
                {vehicleInfo.vin &&
                <div className="ccout">
                <Formsy>
                <div className="cctitle">
                <MyCheckbox 
                    name="postpay" 
                    value={postpay} 
                    id="postpay"
                    labelClass="check-container big"  
                    label="Post Payment Date (MM/DD/YYYY)"
                    checkBoxChange={(e) => this.postpay(e)} 
                />
                </div>
                {postpay>0 &&
                <div>
                <MyInputMask name="startdate" value={initdate} id="startdate" mask="99/99/9999" className="ccbox" 
                valueChanges={(e) => this.dateChange(e)} />
                {!dateVal &&
                <div className="ccerr">Invalid Date</div>
                }
                </div>
                }
                </Formsy>
                </div>
                }

 */}                  <div className="amount-box">
                    <div className="amount-box-inner">
                      
                      <div className="summ">
                      <div className="row"><div className="title">Summary</div></div>
                      <div className="row"><div className="left">{planInfo.levelOfCoverage}</div>
                      <div className="right">${curplan.actual+curplan['deduct'][planInfo.payAtClaimTime]}</div></div>
                      {
                        Object.keys(locSurc).map((s) => {
                          return(
                            <div key={'d'+s}>
                            {locSurc[s] ?
                              <div className="row">
                                <div className="left">{dispSurc[s]}</div>
                                <div className="right">${locSurc[s]}</div>
                              </div> 
                            :''}
                            </div>
                          )
                        })
                      }
                      {discountAmt>0 &&
                        <div className="row">
                        <div className="left">Discount</div>
                        <div className="right">-${discountAmt}</div>
                      </div>
                      }
                      <div className="total"><div className="left">Total</div><div className="right">${(totAmt-discountAmt)}</div></div>
                      </div>
                    </div>
                  </div>
                  
                  {userType=="emp" &&
                  <div>
                    <div onClick={() => { this.resendPayLink() }}  className="btn resend">Resend</div>
                    <div onClick={() => { this.backLink() }}  className="btn resend">Back</div>
                  </div>
                  }
                  
                </div>

                {vehicleInfo.vin &&
                
                <div className="cofirm-left-content payinfo">
                <div className="confirm-box">
                <div className="pay-box-inner">
                <Formsy ref="payFrom" onValidSubmit={this.addCard} onValid={this.enablePay} onInvalid={this.disablePay}>
                <ErrrorHandel formName={'payFrom'} self={this} />
                <h3 className="ctitle">Enter Your Credit Card Details</h3>
                  <div className="form-2">
                    <div className="form-label-group error">
                    <MyInput name="cardFirstName" id="cardFirstName" placeholder="First Name" value={userInfo.firstName} isLabelRequired={true} className="form-control" required/>
                    </div>
                  </div>

                  <div className="form-2">
                    <div className="form-label-group error">
                    <MyInput name="cardLastName" id="cardLastName" placeholder="Last Name" value={userInfo.lastName} isLabelRequired={true} className="form-control" required/>
                    </div>
                  </div>

                  <div className="form-2">
                    <div className="form-label-group error">
                    <MyInputMask name="cardNo" value={''} id="cardNo" mask="9999 9999 9999 9999" placeholder="Credit Card Number" validations={{ maxLength: 19, minLength: 16 }} validationError="Please enter a valid card number."  isLabelRequired= {true} className="form-control" required />
                    </div>
                  </div>

                  <div className="form-2">
                    <div className="form-label-group error">
                    <MyInputMask name="expMMYY" value={''} id="expMMYY" mask="99/99" placeholder="Exp Date (MM/YY)" validationError="Please enter a valid MM/YY." isLabelRequired= {true} className="form-control" required />
                    </div>
                  </div>

                  <div className="form-2">
                    <div className="form-label-group error">
                    <MyInputMask name="cvv" value={''} id="cvv" mask="999" placeholder="CVV" validations={{ maxLength: 3, minLength: 3 }} validationError="Please enter a valid CVV."  isLabelRequired= {true} className="form-control" required />
                    </div>
                  </div>
                  
                  <div className="form-2">
                  <div className="form-label-group error">
                    <div className="error-txt">{gatewayerror}</div>
                  </div>
                  </div>

                  <div className="tearm-checkbox">
                  <MyCheckbox name="cb1" id="cb1" link={true} checkBoxChange={(e) => this.cbchnage('cb1',e)} required labelClass="check-container small" value={cb1} label="I have read and agree to the terms of the" />
                  
                  </div>
                  <div className="tearm-checkbox">
                  <MyCheckbox name="cb2" id="cb2" checkBoxChange={(e) => this.cbchnage('cb2',e)} required labelClass="check-container small" value={cb2} 
                  label={"I agree to be charged $"+ (totAmt-discountAmt) +" per month"} />
                  </div>

                  <div className="tearm-checkbox">
                    <button type="submit" disabled={!canPay || !dateVal} className={(!canPay || !dateVal) ? 'btn disabled' : 'btn'}>Pay Now</button>
                  </div>
                  </Formsy>     
                </div>
                </div>
                </div>
              }
              
            </div>
          </div>
          
        </section>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    step2response: state.register.step2response,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    showLoader: () => dispatch(actionCreators.showLoader()),
    showButtonLoader: () => dispatch(actionCreators.showButtonLoader()),
    hideLoader: () => dispatch(actionCreators.hideLoader()),
    clearAllSteps: () => dispatch(actionCreators.ClearAllSteps())
  }
};
export default connect(mapStateToProps, mapDispatchToProps)(ConfirmPage);